@tailwind base;
@tailwind utilities;

@font-face {
  font-family: Gobold;
  src: url(./assets/font/gobold-regular.otf);
}

@font-face {
  font-family: Montserrat;
  src: url(./assets/font/Montserrat-Light.otf);
  font-weight: 300;
}
@font-face {
  font-family: Montserrat;
  src: url(./assets/font/Montserrat-Medium.otf);
  font-weight: 400;
}


html, body {
  min-height: 100%;

  display: flex;
  flex-direction: column;
}

body {
  @apply text-black font-montserrat;
}

@media screen and (min-width: 600px) {
  body {

  }
}

#root {
  height: 100%
}