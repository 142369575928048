
  .bg-blue-square::before {
    content: "";
}

@media (min-width: 1024px) {
  .bg-blue-square::before {
      content: "";
      @apply absolute h-1/2;
      @apply top-8;
      @apply bg-blue;
      @apply -z-10;
  }
}