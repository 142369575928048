.btn-connect, .dapp-core-component__main__btn-primary {
  height: 2.5rem/* 40px */ !important;
  width: 58.333333% !important;
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
  border-radius: 15px !important;
  border: 2px solid black !important;
  font-size: 12px !important;
  margin: 5px !important;
  transition: 200ms;
}

.btn-connect:hover {
  color: white !important;
  background-color: #00EBC1 !important;
}

.dapp-core-component__dappModalStyles__dappModalContent {
  background: #ffffff !important;
  border: 1px solid #000000 !important;
  border-radius: 10px !important;
}

.dapp-core-component__dappModalStyles__dappModalHeader {
  background-color: transparent !important;
}

.dapp-modal-dialog-close-button {
  background-color: transparent !important;
  padding: 1rem !important;
}

.dapp-core-component__dappModalStyles__dappModalCloseButton:hover,
.dapp-core-component__dappModalStyles__dappModalCloseButton:focus {
  text-decoration: none !important;
  cursor: pointer !important;
  background-color: transparent !important;
  border: none !important;
}

.dapp-core-component__dappModalStyles__dappModalCloseButton:hover svg,
.dapp-core-component__dappModalStyles__dappModalCloseButton:focus svg {
  scale: 1.05 !important;
  color: white !important;
}

.dapp-modal-dialog-close-button svg {
  color: rgba(255, 255, 255, 0.5) !important;
}

.dapp-core-component__dappModalStyles__dappModalHeaderText {
  color: rgba(255, 255, 255, 1) !important;
  padding: 1rem !important;
}

.dapp-core-component__main__mb-3 {
  color: rgba(255, 255, 255, 1) !important;
}

.unlock-buttons-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.unlock-buttons-content span {
  width: 100%;
  text-align: center;
}

.unlock-icon {
  width: 25px;
}

#accessWalletBtn {
  @apply text-center;
  @apply w-7/12 h-10;
  @apply bg-white;
  @apply text-black;
  @apply rounded-full;
}

.dapp-core-component__main__card {
  background-color: transparent !important;
}

.dapp-core-component__main__qr-code-svg-container svg path {
  fill: #010608 !important;
  stroke: rgba(255, 255, 255, 1) !important;
}

/* fixes double modal */
.dapp-wallet-connect-login-modal.dapp-core-component__dappModalStyles__dappModal.dapp-modal-dialog-wrapper {
  z-index: 10 !important;
}
.dapp-ledger-login-container.dapp-core-component__dappModalStyles__dappModal.dapp-modal-dialog-wrapper:has(.dapp-core-component__pageStateStyles__state.dapp-core-component__main__m-auto.dapp-core-component__main__p-4.dapp-core-component__main__text-center.dapp-page-state) {
  z-index: 10 !important;
}

/* No extension button, fix center */
.dapp-core-component__extensionLoginButtonStyles__no-extension-button-wrapper {
  display: none;
}

@media screen and (min-width: 600px) {
  .dapp-core-component__extensionLoginButtonStyles__no-extension-button-wrapper {
    justify-content: center;
    color: black !important;
    padding: 6px 24px;
    display: flex;
    align-items: center;
  }
}
/* Ledger text color */
.dapp-ledger-address-row {
  color: white !important;
}

/* Exit button svg */
.dapp-core-component__dappModalStyles__dappModalCloseButton {
  box-shadow: none  !important;
}

.dapp-core-component__dappModalStyles__dappModalCloseButton:hover svg, .dapp-core-component__dappModalStyles__dappModalCloseButton:focus svg {
  color: black !important;
}

.dapp-modal-dialog-close-button svg {
  color: black !important;
  transition: 200ms;
}

.dapp-modal-dialog-close-button svg:hover {
  color: black !important;
  transform: scale(1.1);

}