.login-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 406px;
    max-width: 90%;
    
    /* Main/Dark Purple */
    
    background: #ffffff;
    border: 3px solid #000000;
    box-shadow: 0px 0px 10px #000000;
    border-radius: 10px;
}

.login-overlay {
    position: fixed;
    background: rgba(0, 0, 0, 0.85);
    inset: 0px;
    z-index: 20;
}

.login-modal-title {
    position: absolute;
    width: 600px;
}

.login-modal-container {
    display:flex;
    flex-direction: row;
}